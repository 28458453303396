import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Showcase/layout";
import Seo from "../components/Showcase/seo";

const IndexPage = () => {
  // useEffect(() => {
  //   window.location.href = "https://eab.com/solutions/digital-agency/"; // External redirect
  // }, [])

  // return <p>Redirecting to external site...</p>; // Optional: Display a message or loader

  return (
    <Layout>
      <Seo title="Digital Agency Services" />
      <section className="intro-section">
        <h1>
          <strong>We Create</strong> Exceptional Website Experiences
        </h1>
        <p>
          A partnership designed to create engaging digital environments for
          prospective students, ensuring site design, navigation, content and
          calls-to-action are converting interest into enrollment.
        </p>
      </section>
      <section className="wrapper">
        <div className="section-title side-title">
          <h2>Website Development Process</h2>
        </div>
        <ul className="content-cards-wrapper">
          <li className="content-card">
            <Link to="discovery-and-planning">
              <StaticImage
                src="../assets/images/discovery-and-planning.png"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>Discovery & Planning</h3>
                <p>
                  Project Plan | Foundational Strategy | Technology & Platform
                  Plan
                </p>
              </div>
            </Link>
          </li>
          <li className="content-card">
            <Link to="information-architecture">
              <StaticImage
                src="../assets/images/information-architecture-navigation-and-global-content-strategy.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>
                  Information Architecture, Navigation & Global Content Strategy
                </h3>
                <p>
                  Content Audit | Information Architecture | Global Strategy
                </p>
              </div>
            </Link>
          </li>
          <li className="content-card">
            <Link to="ux-design">
              <StaticImage
                src="../assets/images/ux-design-wireframes-prototyping-and-user-testing.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>UX Design: Wireframes, Prototyping & User Testing</h3>
                <p>Page Wireframes Development | Responsive Planning</p>
              </div>
            </Link>
          </li>
          <li className="content-card">
            <Link to="visual-design">
              <StaticImage
                src="../assets/images/visual-design-link.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>Visual Design</h3>
                <p>Brand Standards | Design Exploration | Prototyping</p>
              </div>
            </Link>
          </li>
          <li className="content-card">
            <Link to="content-strategy">
              <StaticImage
                src="../assets/images/on-page-content-strategy-copywriting-production-and-planning.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>
                  On-page Content Strategy, Copywriting & Production Planning
                </h3>
                <p>
                  Page-by-Page Content Strategy | Copywriting | Interactive Web
                  Content | User Acceptance Testing
                </p>
              </div>
            </Link>
          </li>
          <li className="content-card">
            <Link to="seo-analytics-and-reporting">
              <StaticImage
                src="../assets/images/seo-analytics-and-reporting.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>SEO Analytics & Reporting</h3>
                <p>
                  Data Dashboard | SEO Reporting | Optimization Recommendations
                </p>
              </div>
            </Link>
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default IndexPage;
